<template>
  <div>
    <DashboardSection :title="locale.dataTitle">
      <ProductUrl :model="model" />
      <MobileProductUrl :model="model" />
      <ChatbotText :model="model" />
      <!-- <CompanionHeading :model="model" /> -->
      <!-- <CompanionText :model="model" /> -->
      <!-- <CompanionCta :model="model" /> -->
      <QrCode :model="model" />
    </DashboardSection>
    <DashboardSection :title="locale.pixelsTitle">
      <PixelClicks :model="model" />
      <PixelClicksScripts :model="model" />
      <PixelImpressions :model="model" />
      <!-- <PixelInspections :model="model" /> -->
    </DashboardSection>
  </div>
</template>

<script>
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import ChatbotText from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/ChatbotText'
import MobileProductUrl from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/MobileProductUrl'
// import CompanionCta from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/CompanionCta'
// import CompanionHeading from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/CompanionHeading'
// import CompanionText from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/CompanionText'
import PixelClicks from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/PixelClicks'
import PixelClicksScripts from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/PixelClicksScripts'
import PixelImpressions from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/PixelImpressions'
// import PixelInspections from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/PixelInspections'
import ProductUrl from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/ProductUrl'
import QrCode from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/QrCode'

export default {
  components: {
    DashboardSection,
    ProductUrl,
    MobileProductUrl,
    ChatbotText,
    // CompanionHeading,
    // CompanionText,
    // CompanionCta,
    QrCode,
    PixelClicks,
    PixelClicksScripts,
    PixelImpressions,
    // PixelInspections,
  },

  props: {
    model: VueTypes.object.isRequired,
  },

  computed: {
    locale: ({ $locale }) => ({
      dataTitle: $locale('form.data.title'),
      pixelsTitle: $locale('form.pixels.title'),
    }),
  },
}
</script>
