<template>
  <ElFormItem
    :prop="field"
    :errors="errors[field]"
  >
    <div class="text-m-bold">{{ locale.label }}</div>
    <ElAlert
      type="success"
      :show-icon="true"
    >
      <div
        class="text-m-regular"
        v-html="locale.alert"
      />
    </ElAlert>
    <ElInput
      class="view-pixels__input"
      v-for="item in links"
      :key="item.id"
      :placeholder="locale.placeholder"
      v-model="item.value"
    />

    <PlusButton
      @click="addItem"
      :disabled="disabledAddButton"
    >
      {{ locale.addLabel }}
    </PlusButton>
  </ElFormItem>
</template>

<script>
import ElAlert from 'element/ElAlert'
import ElFormItem from 'element/ElFormItem'
import ElInput from 'element/ElInput'
import PlusButton from 'components/buttons/PlusButton'

export default {
  components: {
    ElAlert,
    ElFormItem,
    ElInput,
    PlusButton,
  },

  props: {
    model: VueTypes.object.isRequired,
  },

  data: () => ({
    field: 'pixel_impressions',
  }),

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('form.data.fields.pixelImpressions.label'),
      placeholder: $locale('form.data.fields.pixelImpressions.placeholder'),
      addLabel: $locale('form.data.fields.pixelImpressions.addLabel'),
      alert: $locale('form.data.fields.pixelImpressions.alert'),
    }),
    links: ({ model, field }) => model[field],
    errors: ({ $store }) => $store.getters['creative/errors'],

    disabledAddButton ({ links }) {
      if (links.find(item => item.value === '')) return true

      const uniqueValues = []
      let hasSameValues = false

      links.map(item => {
        if (uniqueValues.includes(item.value)) {
          hasSameValues = true
        }
        else {
          uniqueValues.push(item.value)
        }
      })

      return hasSameValues
    },
  },

  methods: {
    addItem () {
      this.model[this.field].push({
        id: new Date().getTime(),
        value: '',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.text-m-bold {
  margin-bottom: 12px;
}

.view-pixels {

  &__input {
    margin-bottom: 15px;
  }
}
.el-alert {
  margin-bottom: 8px;

  .text-m-regular {
    margin: 0;
    color: $--color-success;
  }
}

::v-deep {
  .el-alert__icon {
    font-size: 14px;
    width: 14px;
    padding-top: 4px;
  }
}
</style>
